import type { RuleExpression } from 'vee-validate';

export type FieldContext<T extends object, K extends keyof T> = {
    name: K;
    rules: RuleExpression<string | undefined>;
    label: string | undefined;
};

export type FieldsContext<T extends object> = {
    [K in keyof T]: FieldContext<T, K>;
};

export type ExtendedFieldContext = {
    name: string;
    rules: RuleExpression<string | undefined>;
    label: string | undefined;
};

export type ExtendedFieldsContext<T extends object> = {
    [K in keyof T]: ExtendedFieldContext;
};

export function timezoneValidation(value: string) {
    const timeZoneRegex = /^[a-zA-Z]+\/[a-zA-Z_-]+(?:\/[a-zA-Z_-]+)?$/;
    if (!value || timeZoneRegex.test(value)) {
        return true;
    }
    return 'Invalid time zone format';
}
